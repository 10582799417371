import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2da562b1 = () => interopDefault(import('../pages/candidate.vue' /* webpackChunkName: "pages/candidate" */))
const _63c9bd42 = () => interopDefault(import('../pages/candidate/_id/index.vue' /* webpackChunkName: "pages/candidate/_id/index" */))
const _315cdd8e = () => interopDefault(import('../pages/candidate/_id/academy.vue' /* webpackChunkName: "pages/candidate/_id/academy" */))
const _177d2712 = () => interopDefault(import('../pages/candidate/_id/activity.vue' /* webpackChunkName: "pages/candidate/_id/activity" */))
const _2c607d0c = () => interopDefault(import('../pages/candidate/_id/autoSearch.vue' /* webpackChunkName: "pages/candidate/_id/autoSearch" */))
const _51c9c596 = () => interopDefault(import('../pages/candidate/_id/general.vue' /* webpackChunkName: "pages/candidate/_id/general" */))
const _60f8580e = () => interopDefault(import('../pages/candidate/_id/interview.vue' /* webpackChunkName: "pages/candidate/_id/interview" */))
const _2262e852 = () => interopDefault(import('../pages/candidate/_id/matchesTalks.vue' /* webpackChunkName: "pages/candidate/_id/matchesTalks" */))
const _1ed5fc83 = () => interopDefault(import('../pages/candidate/_id/referrals.vue' /* webpackChunkName: "pages/candidate/_id/referrals" */))
const _b245ebaa = () => interopDefault(import('../pages/company.vue' /* webpackChunkName: "pages/company" */))
const _63ffbdc8 = () => interopDefault(import('../pages/company/_id/contacts.vue' /* webpackChunkName: "pages/company/_id/contacts" */))
const _59815daf = () => interopDefault(import('../pages/company/_id/general.vue' /* webpackChunkName: "pages/company/_id/general" */))
const _5f5337c2 = () => interopDefault(import('../pages/company/_id/jobs.vue' /* webpackChunkName: "pages/company/_id/jobs" */))
const _4fa7d621 = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _72fbf72a = () => interopDefault(import('../pages/job.vue' /* webpackChunkName: "pages/job" */))
const _c40f9700 = () => interopDefault(import('../pages/job/_id/autoSearch.vue' /* webpackChunkName: "pages/job/_id/autoSearch" */))
const _8f7e1022 = () => interopDefault(import('../pages/job/_id/general.vue' /* webpackChunkName: "pages/job/_id/general" */))
const _2d223d88 = () => interopDefault(import('../pages/job/_id/missionSheet.vue' /* webpackChunkName: "pages/job/_id/missionSheet" */))
const _5e3033ea = () => interopDefault(import('../pages/job/_id/permissions.vue' /* webpackChunkName: "pages/job/_id/permissions" */))
const _571e1607 = () => interopDefault(import('../pages/job/_id/pitch.vue' /* webpackChunkName: "pages/job/_id/pitch" */))
const _c5d60b06 = () => interopDefault(import('../pages/job/_id/ratings.vue' /* webpackChunkName: "pages/job/_id/ratings" */))
const _799056d2 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _7776904a = () => interopDefault(import('../pages/ai_tools/aiTools.vue' /* webpackChunkName: "pages/ai_tools/aiTools" */))
const _28a84a0c = () => interopDefault(import('../pages/ai_tools/kiaditca.js' /* webpackChunkName: "pages/ai_tools/kiaditca" */))
const _c03e8256 = () => interopDefault(import('../pages/listing/candidate-admitted.vue' /* webpackChunkName: "pages/listing/candidate-admitted" */))
const _f16c9d4c = () => interopDefault(import('../pages/listing/candidate-applications.vue' /* webpackChunkName: "pages/listing/candidate-applications" */))
const _1ab0e564 = () => interopDefault(import('../pages/listing/candidate-applications/interested.vue' /* webpackChunkName: "pages/listing/candidate-applications/interested" */))
const _247396eb = () => interopDefault(import('../pages/listing/candidate-applications/new.vue' /* webpackChunkName: "pages/listing/candidate-applications/new" */))
const _ee6f3fbc = () => interopDefault(import('../pages/listing/candidate-applications/opport-check.vue' /* webpackChunkName: "pages/listing/candidate-applications/opport-check" */))
const _dba932ca = () => interopDefault(import('../pages/listing/candidate-applications/refused.vue' /* webpackChunkName: "pages/listing/candidate-applications/refused" */))
const _57c07df5 = () => interopDefault(import('../pages/listing/candidate-db.vue' /* webpackChunkName: "pages/listing/candidate-db" */))
const _c6b86d62 = () => interopDefault(import('../pages/listing/candidate-referrals.vue' /* webpackChunkName: "pages/listing/candidate-referrals" */))
const _3cae344c = () => interopDefault(import('../pages/listing/candidate-referrals/no-referrals.vue' /* webpackChunkName: "pages/listing/candidate-referrals/no-referrals" */))
const _7b250924 = () => interopDefault(import('../pages/listing/candidate-referrals/ref-created.vue' /* webpackChunkName: "pages/listing/candidate-referrals/ref-created" */))
const _21fc969d = () => interopDefault(import('../pages/listing/candidate-referrals/ref-pending.vue' /* webpackChunkName: "pages/listing/candidate-referrals/ref-pending" */))
const _2c881d74 = () => interopDefault(import('../pages/listing/candidate-referrals/ref-requested.vue' /* webpackChunkName: "pages/listing/candidate-referrals/ref-requested" */))
const _339e2a9f = () => interopDefault(import('../pages/listing/candidate-search.vue' /* webpackChunkName: "pages/listing/candidate-search" */))
const _5154c97a = () => interopDefault(import('../pages/listing/jobs.vue' /* webpackChunkName: "pages/listing/jobs" */))
const _390cf254 = () => interopDefault(import('../pages/listing/matches.vue' /* webpackChunkName: "pages/listing/matches" */))
const _1dd17785 = () => interopDefault(import('../pages/listing/matches/candidate-check.vue' /* webpackChunkName: "pages/listing/matches/candidate-check" */))
const _abdd552e = () => interopDefault(import('../pages/listing/matches/check-before-admission.vue' /* webpackChunkName: "pages/listing/matches/check-before-admission" */))
const _09eeeacf = () => interopDefault(import('../pages/listing/matches/client-check.vue' /* webpackChunkName: "pages/listing/matches/client-check" */))
const _885ba612 = () => interopDefault(import('../pages/listing/matches/hired.vue' /* webpackChunkName: "pages/listing/matches/hired" */))
const _3409d0f4 = () => interopDefault(import('../pages/listing/matches/interested-before-admission.vue' /* webpackChunkName: "pages/listing/matches/interested-before-admission" */))
const _baaf61fe = () => interopDefault(import('../pages/listing/matches/internal-check.vue' /* webpackChunkName: "pages/listing/matches/internal-check" */))
const _6d4d14a2 = () => interopDefault(import('../pages/listing/matches/interview-steps.vue' /* webpackChunkName: "pages/listing/matches/interview-steps" */))
const _18083163 = () => interopDefault(import('../pages/listing/matches/offer.vue' /* webpackChunkName: "pages/listing/matches/offer" */))
const _10dc0017 = () => interopDefault(import('../pages/listing/matches/refused.vue' /* webpackChunkName: "pages/listing/matches/refused" */))
const _0cdf9d00 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/candidate",
    component: _2da562b1,
    name: "candidate",
    children: [{
      path: ":id",
      component: _63c9bd42,
      name: "candidate-id"
    }, {
      path: ":id?/academy",
      component: _315cdd8e,
      name: "candidate-id-academy"
    }, {
      path: ":id?/activity",
      component: _177d2712,
      name: "candidate-id-activity"
    }, {
      path: ":id?/autoSearch",
      component: _2c607d0c,
      name: "candidate-id-autoSearch"
    }, {
      path: ":id?/general",
      component: _51c9c596,
      name: "candidate-id-general"
    }, {
      path: ":id?/interview",
      component: _60f8580e,
      name: "candidate-id-interview"
    }, {
      path: ":id?/matchesTalks",
      component: _2262e852,
      name: "candidate-id-matchesTalks"
    }, {
      path: ":id?/referrals",
      component: _1ed5fc83,
      name: "candidate-id-referrals"
    }]
  }, {
    path: "/company",
    component: _b245ebaa,
    name: "company",
    children: [{
      path: ":id?/contacts",
      component: _63ffbdc8,
      name: "company-id-contacts"
    }, {
      path: ":id?/general",
      component: _59815daf,
      name: "company-id-general"
    }, {
      path: ":id?/jobs",
      component: _5f5337c2,
      name: "company-id-jobs"
    }]
  }, {
    path: "/home",
    component: _4fa7d621,
    name: "home"
  }, {
    path: "/job",
    component: _72fbf72a,
    name: "job",
    children: [{
      path: ":id?/autoSearch",
      component: _c40f9700,
      name: "job-id-autoSearch"
    }, {
      path: ":id?/general",
      component: _8f7e1022,
      name: "job-id-general"
    }, {
      path: ":id?/missionSheet",
      component: _2d223d88,
      name: "job-id-missionSheet"
    }, {
      path: ":id?/permissions",
      component: _5e3033ea,
      name: "job-id-permissions"
    }, {
      path: ":id?/pitch",
      component: _571e1607,
      name: "job-id-pitch"
    }, {
      path: ":id?/ratings",
      component: _c5d60b06,
      name: "job-id-ratings"
    }]
  }, {
    path: "/login",
    component: _799056d2,
    name: "login"
  }, {
    path: "/ai_tools/aiTools",
    component: _7776904a,
    name: "ai_tools-aiTools"
  }, {
    path: "/ai_tools/kiaditca",
    component: _28a84a0c,
    name: "ai_tools-kiaditca"
  }, {
    path: "/listing/candidate-admitted",
    component: _c03e8256,
    name: "listing-candidate-admitted"
  }, {
    path: "/listing/candidate-applications",
    component: _f16c9d4c,
    name: "listing-candidate-applications",
    children: [{
      path: "interested",
      component: _1ab0e564,
      name: "listing-candidate-applications-interested"
    }, {
      path: "new",
      component: _247396eb,
      name: "listing-candidate-applications-new"
    }, {
      path: "opport-check",
      component: _ee6f3fbc,
      name: "listing-candidate-applications-opport-check"
    }, {
      path: "refused",
      component: _dba932ca,
      name: "listing-candidate-applications-refused"
    }]
  }, {
    path: "/listing/candidate-db",
    component: _57c07df5,
    name: "listing-candidate-db"
  }, {
    path: "/listing/candidate-referrals",
    component: _c6b86d62,
    name: "listing-candidate-referrals",
    children: [{
      path: "no-referrals",
      component: _3cae344c,
      name: "listing-candidate-referrals-no-referrals"
    }, {
      path: "ref-created",
      component: _7b250924,
      name: "listing-candidate-referrals-ref-created"
    }, {
      path: "ref-pending",
      component: _21fc969d,
      name: "listing-candidate-referrals-ref-pending"
    }, {
      path: "ref-requested",
      component: _2c881d74,
      name: "listing-candidate-referrals-ref-requested"
    }]
  }, {
    path: "/listing/candidate-search",
    component: _339e2a9f,
    name: "listing-candidate-search"
  }, {
    path: "/listing/jobs",
    component: _5154c97a,
    name: "listing-jobs"
  }, {
    path: "/listing/matches",
    component: _390cf254,
    name: "listing-matches",
    children: [{
      path: "candidate-check",
      component: _1dd17785,
      name: "listing-matches-candidate-check"
    }, {
      path: "check-before-admission",
      component: _abdd552e,
      name: "listing-matches-check-before-admission"
    }, {
      path: "client-check",
      component: _09eeeacf,
      name: "listing-matches-client-check"
    }, {
      path: "hired",
      component: _885ba612,
      name: "listing-matches-hired"
    }, {
      path: "interested-before-admission",
      component: _3409d0f4,
      name: "listing-matches-interested-before-admission"
    }, {
      path: "internal-check",
      component: _baaf61fe,
      name: "listing-matches-internal-check"
    }, {
      path: "interview-steps",
      component: _6d4d14a2,
      name: "listing-matches-interview-steps"
    }, {
      path: "offer",
      component: _18083163,
      name: "listing-matches-offer"
    }, {
      path: "refused",
      component: _10dc0017,
      name: "listing-matches-refused"
    }]
  }, {
    path: "/",
    component: _0cdf9d00,
    name: "index"
  }, {
    path: "/company/:id/",
    redirect: "/company/:id/general",
    name: "companyId"
  }, {
    path: "/job/:id/",
    redirect: "/job/:id/general",
    name: "jobId"
  }, {
    path: "/listing/matches/",
    redirect: "/listing/matches/internal-check",
    name: "listingMatches"
  }, {
    path: "/listing/application/",
    redirect: "/listing/candidate-applications/new",
    name: "candidateListing"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
