export class SearchFilter {
    public operator = ''
    public value: Array<string> | number | Date
    public conditions: Array<Record<string, unknown>>
}
export class SearchQuery {
    public constructor(
        public terms: string = '',
        public filters: Record<string, SearchFilter> = {},
        public sortField: string = '_score',
        public sortOrder: string = 'desc',
        public page: number,
        public size: number
    ) {}
}

export class SearchResults {
    public constructor(
        public results: Array<Record<string, any>> = [],
        public aggregations: Record<string, any> = {},
        public total: number
    ) {}

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static fromObject(searchResults: any): SearchResults {
        return Object.assign(new SearchResults(searchResults.results, searchResults.aggregations, searchResults.total))
    }
}

export class FacetsResults {
    [k: string]: Record<number, string>
    public constructor(
        public coaches: Record<number, string> = {},
        public statuses: Record<number, string> = {},
        public currentJobSearch: Record<number, string> = {},
        public practices: Record<number, string> = {},
        public cities: Record<number, string> = {},
        public jobs: Record<number, string> = {},
        public languages: Record<number, string> = {},
        public remotePolicies: Record<number, string> = {},
        public softSkills: Record<number, string> = {},
        public masteredTechnologies: Record<number, string> = {},
        public masteredIndustries: Record<number, string> = {},
        public masteredBusinessModels: Record<number, string> = {},
        public masteredSegmentations: Record<number, string> = {}
    ) {}

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static fromObject(facetsResults: any): FacetsResults {
        return Object.assign(
            new FacetsResults(
                facetsResults.coaches,
                facetsResults.statuses,
                facetsResults.currentJobSearch,
                facetsResults.practices,
                facetsResults.cities,
                facetsResults.jobs,
                facetsResults.languages,
                facetsResults.remotePolicies,
                facetsResults.softSkills,
                facetsResults.masteredTechnologies,
                facetsResults.masteredIndustries,
                facetsResults.masteredBusinessModels,
                facetsResults.masteredSegmentations
            )
        )
    }
}

export class Search {
    public constructor(
        public readonly id: number = 1,
        public name: string = '',
        public terms: string = '',
        public filters: Record<string, any> = {}
    ) {}

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static fromObject(search: any): Search {
        return Object.assign(new Search(search.id), search)
    }
}
